export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBRJRYWoOxSOXEoXO4fxiPzaWXGmoaIeJI',
    authDomain: 'sanhei-bills.firebaseapp.com',
    databaseURL: 'https://sanhei-bills.firebaseio.com',
    projectId: 'sanhei-bills',
    storageBucket: 'sanhei-bills.appspot.com',
    messagingSenderId: '1051872822136',
    appId: '1:1051872822136:web:c5e81474612dd2abaf7e8d',
  },
}
