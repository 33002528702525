<section>
  <mat-spinner
    *ngIf="userSession.status === 'unknown'"
    diameter="40"
    strokeWidth="6"
    color="accent"
  ></mat-spinner>
  <div *ngIf="userSession.status === 'signedOut'">
    <button mat-raised-button (click)="login()">Sign in</button>
  </div>
  <div *ngIf="userSession.status === 'signedIn'">
    <sb-account-info [user]="userSession.user"></sb-account-info>
  </div>
</section>
