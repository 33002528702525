<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <sb-articles-form [formGroup]="form" [articles]="bill?.articles"></sb-articles-form>

  <mat-card>
    <mat-form-field *ngIf="!createNewBill">
      <input matInput formControlName="humanId" placeholder="ID" />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        formControlName="billType"
        placeholder="Art (Rechnung / Offerte / Spezial)"
        [matAutocomplete]="billTypeAuto"
      />
    </mat-form-field>
    <mat-autocomplete #billTypeAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['billType'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <input
        matInput
        formControlName="title"
        placeholder="Titel Rechnung"
        [matAutocomplete]="titleAuto"
      />
    </mat-form-field>
    <mat-autocomplete (optionSelected)="titleSelected($event)" #titleAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['title'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <textarea
        matInput
        formControlName="address"
        cdkTextareaAutosize
        placeholder="Adresse"
        [matAutocomplete]="addressAuto"
      ></textarea>
    </mat-form-field>
    <mat-autocomplete (optionSelected)="addressSelected($event)" #addressAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['address'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <input
        matInput
        formControlName="ownerName"
        placeholder="Eigentümer"
        [matAutocomplete]="ownerNameAuto"
      />
    </mat-form-field>
    <mat-autocomplete #ownerNameAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['ownerName'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <input
        matInput
        formControlName="ordererName"
        placeholder="Auftraggeber/Nr"
        [matAutocomplete]="ordererNameAuto"
      />
    </mat-form-field>
    <mat-autocomplete #ordererNameAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['ordererName'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-card>

  <mat-card>
    <mat-form-field>
      <input
        matInput
        formControlName="descriptionTitle"
        placeholder="Titel Beschreibung"
        [matAutocomplete]="descriptionTitleAuto"
      />
    </mat-form-field>
    <mat-autocomplete
      (optionSelected)="descriptionTitleSelected($event)"
      #descriptionTitleAuto="matAutocomplete"
    >
      <mat-option
        *ngFor="let option of autocompleteOptions['descriptionTitle'] | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <textarea
        matInput
        formControlName="description"
        cdkTextareaAutosize
        placeholder="Arbeitsbeschreibung"
        [matAutocomplete]="descriptionAuto"
      ></textarea>
    </mat-form-field>
    <mat-autocomplete #descriptionAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['description'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-card>

  <mat-card>
    <mat-form-field>
      <input
        matInput
        formControlName="workedAt"
        placeholder="Ausgeführte Arbeiten am (Freitext oder yyyy-mm-dd, yyyy-mm-dd, ... als Datum)"
      />
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="orderedAt" placeholder="Auftragsdatum (yyyy-mm-dd)" />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="billedAt"
        placeholder="Verrechnet am (yyyy-mm-dd), wird beim Drucken automatisch gesetzt falls leer"
      />
    </mat-form-field>
  </mat-card>

  <mat-card>
    <mat-form-field>
      <input matInput formControlName="cashback" placeholder="Skonto in %" />
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="vat" placeholder="MWST in %" />
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="discount" placeholder="Rabatt" />
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="paymentDeadlineInDays" placeholder="Zahlbar innert ... Tagen" />
    </mat-form-field>
  </mat-card>

  <button mat-raised-button color="primary" type="submit">SPEICHERN</button>

  <button mat-raised-button class="abort-button" type="button" (click)="abort()">ABBRECHEN</button>

  <button mat-fab color="primary" class="floating-save-button" type="submit">
    <mat-icon class="md-24" aria-label="Speichern">save</mat-icon>
  </button>
</form>
