<mat-card *ngIf="showDetails">
  <mat-card-header *ngIf="user">
    <img mat-card-avatar (click)="toggle()" [src]="user.photoURL" alt="Profile Image" />
    <mat-card-title>{{ user.displayName }}</mat-card-title>
    <mat-card-subtitle>{{ user.email }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions layout="row" layout-align="end center">
    <button mat-button (click)="logout()">Sign out</button>
  </mat-card-actions>
</mat-card>

<div class="preview">
  <img
    *ngIf="user"
    (click)="toggle()"
    [src]="user.photoURL"
    class="mat-card-image"
    alt="Profile Image"
  />
</div>
